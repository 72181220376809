import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import Blog from '../components/Blog'

function Admission() {
  return (
    <>
    <PageHeader title="Admission Process" />
    <PageContent 
       elem={<Blog image='' showImage={false} content='<div>
       <p>
       Admission to UG courses is given on online merit basis strictly in accordance with reservation policies of the state government generated by Bundelkhand University, Jhansi. The admission in PG courses through Entrance Examination conducted by Bundelkhand University, Jhansi. The students primarily belong to rural areas. The college has highly qualified teaching staff selected through UPPSC, Allahabad who teach the students in interactive manner and employ classroom teaching as a principal method of teaching and also use other methods like seminars, projects, charts and use of projectors to improve the quality.
       </p>
       </div>' />}/>
     
    </>
  )
}

export default Admission