import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import Blog from '../components/Blog';
import { useSelector } from 'react-redux';


function PrincipalMessage() {

  const store = useSelector((state) => state.auth.store);
  return (
    <div>
      <PageHeader title="Pricipal Message" />
      <PageContent elem={
        <div>
          <div>
            <h2>Message From The Principal's Desk</h2>
            <div style={{textAlign:'center',fontSize:'20px'}}>
              <p  style={{margin:'0px',padding:'0px'}}>
                <strong>
                विद्या ददाति विनयं विनयात् याति पात्रताम्।
                </strong>
                </p>
                <p style={{margin:'0px',padding:'0px'}}>
                  <strong>
                पात्रत्वाद्धनमाप्नोति धनाद्धर्मं ततः सुखम्॥हितोपदेश
                </strong>
              </p>
              <p  style={{margin:'0px',padding:'0px',marginTop:'10px'}}>
              Knowledge gives humility, from humility, one attains character; From character,
              </p><p  style={{margin:'0px',padding:'0px'}}>
              one acquires wealth; from wealth good deeds (righteousness) follow and then happiness.
              </p>
            </div>

          </div>
        <Blog image={`https://campus.acetians.in/custom/images/${store.principal_image}`} content={store.principal_message} /></div>}/>
    </div>
  )
}

export default PrincipalMessage;
