import React, { useRef } from 'react';

const LatestNewsMarquee = ({item}) => {
  const marqueeRef = useRef(null);

  const handleMouseOver = () => {
    if (marqueeRef.current) {
      marqueeRef.current.stop();
    }
  };

  const handleMouseOut = () => {
    if (marqueeRef.current) {
      marqueeRef.current.start();
    }
  };

  return (
    <section id="latestnews">
      <div className="breaking-news-ticker bn-effect-scroll bn-direction-ltr" style={{ height: '40px', lineHeight: '38px', borderRadius: '2px', borderWidth: '1px' }}>
        <div className="bn-label" id="main-content">
          <span>What's New</span> <i className="fa fa-bullhorn"></i>
        </div>
        <div className="bn-news">
          <marquee
            ref={marqueeRef}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <ul>
            {(<div dangerouslySetInnerHTML={{ __html: item }} />)}
            </ul>
          </marquee>
        </div>
        <div className="bn-controls">
          <button>
            <span className="bn-action bn-pause"></span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default LatestNewsMarquee;
