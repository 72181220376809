import React from 'react'
import PageHeader from '../components/PageHeader'
import Blog from '../components/Blog'
import PageContent from '../components/PageContent'

function Syllabus() {
  return (
    <>
    <PageHeader title="Admission Rules" />
    <PageContent 
       elem={<Blog image='' content='<div>
        <h1 style="font-weight:bolder" >SYLLABUS</h1>
        <p>
       The college is affiliated to the ‘Bundelkhand University’, Jhansi and all the examinations of the college are conducted by the University. For examinations students are governed by the Rules and Regulations of the University. They get their mark sheets and degrees from the university. The courses are conducted in the college as per the syllabus prescribed by the University. The hard copy of the syllabus is available in the Departments of the college.
              </p>
              <p>
Students can also downloadthe syllabus from <a style="color:red" href="https://www.bujhansi.ac.in/en/page/syllabi.">Syllabus Link</a>
       </p></div>' />}/>
     
    </>
  )
}

export default Syllabus