import React from 'react'
import PageHeader from '../components/PageHeader'

import PageContent from '../components/PageContent'
import Blog from '../components/Blog'

function Facility(props) {
  return (
    <div>
      <PageHeader title={props.facility.name} />
      <PageContent elem={<Blog image={props.facility.image} content={props.facility.description} />}/>
    </div>
  )
}

export default Facility