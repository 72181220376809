import React from 'react'
import PageHeader from '../components/PageHeader'
import Blog from '../components/Blog'
import PageContent from '../components/PageContent'

function AdmissionRules() {
  return (
    <>
    <PageHeader title="Admission Rules"/>

    <PageContent 
       elem={<Blog image='' showImage={false} content='
             <div class="admission-rules">
             <h2 style="font-weight:bolder" >GENERAL RULES FOR ADMISSION</h2>
             <ul>
                <li>
                    For the admission in the UG/PG courses in the college, students are advised to visit the website of Bundelkhand University, Jhansi and follow online admission procedure
                </li>
                <li>
                Bundelkhand University Conducts Entrance Test(Postgraduate) or gives Direct Admission based online registration (Undergraduate) to various courses/subjects. Admission is given according to merit in the Entrance Test/Direct forms, subject to fulfilling the eligibility requirements and availability of seats
                </li>
                <li>
                The advantage of reservation is available only for natives of Uttar Pradesh as per state government rules. Candidates from other States belonging to reserved category are treated in General Category.
                </li>
                <li>
                At the time of seeking admission to a course an Affidavit has to be submitted if there is a break in studies from the current year to the previous year. The maximum period allowed for such break can be two years only and some percentage of marks per year are deducted for such breaks as per university rules.
                </li>
                <li>
                While seeking admission the students have to apply separately to each faculty if so desired. In no case the forms will be transferred from one faculty to other faculty.
                </li>
                <li>
                Inter-faculty transfers are not allowed. Once admitted in a faculty, the admission is final.
                </li>
                <li>
                No admissions are made in excess of no. of seats available in a course or in contravention of the rules. The Principal shall have the final powers to cancel any such admission if found at any stage of the course.
                </li>
                <li>
                No person who is a history-sheeter according to the police records or has been convicted of an offence involving moral turpitude shall be given admission in the college, and if already admitted, his/her admission shall be cancelled immediately after the such facts are become known to the college.
                </li>
                <li>
                A candidate who already has any of the post-graduate degrees shall not be given admission to the post- graduate degree course, unless facilities to appear as a private candidate for the post graduate degree exist.
                </li>
                <li>
                No fresh admission to the II and III year of Graduate level course and II year of Post Graduate level course in any subject is entertained unless permitted by the Vice-Chancellor.
                </li>
                <li>
                A student who does not complete 75% attendance in particular courses(s) shall not be given admission as a regular student in that year. He/she should appear in the examination for that course(s) as an ex-student.
                </li>
             </ul>
             </div>
            '
        />}/>
     
    </>
  )
}

export default AdmissionRules