import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import Blog from '../components/Blog'
import { useSelector } from 'react-redux';

function AboutUsPage() {
  const store = useSelector((state) => state.auth.store);
  return (
    /*
     {
          "title": "Vision & Mission",
          "link": "/about/vision-mission/"
        },
        {
          "title": "We value their Services",
          "link": "#",
          "subMenu": [
            {
              "title": "Our Past Principals",
              "link": "/about/we-value-their-services/our-past-principal/"
            },
            {
              "title": "Our Past Faculties",
              "link": "/about/we-value-their-services/our-past-faculties/"
            }
          ]
        }
    */
    <div>
      <PageHeader title="About Us" />
      <PageContent elem={<Blog image={store.about_image} content={store.about_page} />}/>
    </div>
  )
}

export default AboutUsPage